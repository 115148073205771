
import { defuFn } from '/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/node_modules/.pnpm/defu@6.1.4/node_modules/defu/dist/defu.mjs'

const inlineConfig = {}

import cfg0 from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/app.config.ts"
import cfg1 from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/layers/tairo/app.config.ts"
import cfg2 from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/node_modules/.pnpm/@shuriken-ui+nuxt@0.2.9_nuxt@3.5.0/node_modules/@shuriken-ui/nuxt/app.config.ts"
import cfg3 from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/layers/tairo-layout-sidebar/app.config.ts"
import cfg4 from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/layers/tairo-layout-collapse/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, cfg1, cfg2, cfg3, cfg4, inlineConfig)

/**
 * Starts the authentication flow
 *
 * @param {Object} store
 * @returns {Promise<void>}
 */
export async function initAuth(store) {
  try {
    await store.getClient()
    await store.getUser()
  } catch (e) {
    if (e.error === 'login_required' || e.error === 'consent_required') {
      return navigateTo({ path: '/' })
    } else {
      console.error('error while fetching user or role')
      throw showError(e)
    }
  }
}

/**
 * At this point the getUser function is called but since we're not `await`-ing it, this part of the code is executed
 * on the client side.
 * This allows us to show a brief "Authenticating..." screen until we figure out whether to send the user
 * to the Auth0 login screen or to fetch the token, user and role.
 *
 * Any other operations that require the user or token need to be trigger after the Promise from getUser() is resolved.
 * In this case, after the tokens is resolved, we fetch the contractors and assign them to the shared state value.
 *
 * @param {Object} appConfig
 * @param {Object} store
 * @param {Ref<any[]>} contractorsForNav
 * @returns {Promise<void>}
 */
export async function prepareLayout(appConfig, store, contractorsForNav) {
  store.client.isAuthenticated().then(async (isAuthenticated) => {
    if (!isAuthenticated) {
      await store.client.loginWithRedirect()
    }
  })
  if (store.role.name === 'contractor') {
    prepareLayoutForContractor(store.user, appConfig)
    return
  }

  addNotificationsInToolbar(appConfig)

  const { index, getNavContractors } = useContractors()

  const { data } = await useAsyncData('contractors_for_nav', () =>
    getNavContractors(),
  )
  contractorsForNav.value = data.value.data
}

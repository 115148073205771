export default defineNuxtPlugin((nuxtApp) => {
  // nuxtApp.hook('vue:error', (..._args) => {
  //   console.log('vue:error')
  //   // if (process.client) {
  //   //   console.log(..._args)
  //   // }
  //   showError('Something went wrong')
  // })
  // nuxtApp.hook('app:error', (..._args) => {
  //   console.log('app:error')
  //   if (process.client) {
  //     console.log(..._args)
  //   }
  //   // showError('Something went wrong')
  // })
  // nuxtApp.vueApp.config.errorHandler = (..._args) => {
  //   console.log('global error handler')
  //   if (process.client) {
  //     console.log(..._args)
  //   }
  //   // showError('Something went wrong')
  // }
})

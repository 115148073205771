import { AppConfig } from '@nuxt/schema'
import { User } from '@auth0/auth0-spa-js'

export function prepareLayoutForContractor(user: User, appConfig: AppConfig) {
  // Only show the authenticated contractor's board
  if (appConfig.tairo.collapse?.navigation) {
    appConfig.tairo.collapse.navigation.items = [
      {
        name: 'Claims',
        icon: {
          name: 'material-symbols:line-start-circle-outline-rounded',
          class: 'w-4 h-4',
        },
        activePath: `/board/${user.contractor_id}`,
        to: `/board/${user.contractor_id}`,
      },
    ]
  }
}

export function addNotificationsInToolbar(appConfig: AppConfig) {
  if (appConfig.tairo.collapse?.toolbar?.tools) {
    appConfig.tairo.collapse.toolbar.tools.splice(1, 0, {
      component: 'DemoToolbarNotifications',
    })
  }
}

import { useAuthStore } from '~/store/auth'
import { initAuth } from '~/utils/auth'

export default defineNuxtRouteMiddleware(async (to) => {
  // Skip middleware on server
  if (import.meta.server) return

  const store = useAuthStore()

  if (store.user === null) {
    await initAuth(store)
  }

  if (
    isContractor(store.role) &&
    !pathIsContractorsBoard(store.user.contractor_id, to)
  ) {
    console.log('...navigating to board')
    return navigateTo({ path: `/board/${store.user.contractor_id}` })
  }
})

function isContractor(role) {
  if (role && role.name) {
    return role.name === 'contractor'
  }

  return false
}

function pathIsContractorsBoard(contractorId, to) {
  return to.path === `/board/${contractorId}`
}

import plugin_vue3_hit3fKmreM from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.0.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_Pqc1LvFLHP from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/node_modules/.pnpm/nuxt@3.5.0_@parcel+watcher@2.1.0_@types+node@20.14.10_eslint@8.41.0_typescript@5.0.4_vue-tsc@1.6.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/.nuxt/components.plugin.mjs";
import unhead_drS6Oq6GRi from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/node_modules/.pnpm/nuxt@3.5.0_@parcel+watcher@2.1.0_@types+node@20.14.10_eslint@8.41.0_typescript@5.0.4_vue-tsc@1.6.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CYlbHJfk4Y from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/node_modules/.pnpm/nuxt@3.5.0_@parcel+watcher@2.1.0_@types+node@20.14.10_eslint@8.41.0_typescript@5.0.4_vue-tsc@1.6.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_tPWJmoVxih from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/node_modules/.pnpm/nuxt@3.5.0_@parcel+watcher@2.1.0_@types+node@20.14.10_eslint@8.41.0_typescript@5.0.4_vue-tsc@1.6.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_bKokeBHM7z from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_xT0wKQ50TH from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/node_modules/.pnpm/@cssninja+nuxt-toaster@0.3.12/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import debug_lBpfcInGJ7 from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/node_modules/.pnpm/nuxt@3.5.0_@parcel+watcher@2.1.0_@types+node@20.14.10_eslint@8.41.0_typescript@5.0.4_vue-tsc@1.6.5/node_modules/nuxt/dist/app/plugins/debug.js";
import chunk_reload_client_0TvAnPc6Xa from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/node_modules/.pnpm/nuxt@3.5.0_@parcel+watcher@2.1.0_@types+node@20.14.10_eslint@8.41.0_typescript@5.0.4_vue-tsc@1.6.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import error_Z9R5b5Ynf8 from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/plugins/error.js";
import money3_8fWR8yJHEx from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/plugins/money3.js";
import directives_yBPzJQtdia from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/layers/tairo/plugins/directives.ts";
export default [
  plugin_vue3_hit3fKmreM,
  revive_payload_client_Pqc1LvFLHP,
  components_plugin_KR1HBZs4kY,
  unhead_drS6Oq6GRi,
  router_CYlbHJfk4Y,
  prefetch_client_tPWJmoVxih,
  plugin_client_bKokeBHM7z,
  plugin_xT0wKQ50TH,
  debug_lBpfcInGJ7,
  chunk_reload_client_0TvAnPc6Xa,
  error_Z9R5b5Ynf8,
  money3_8fWR8yJHEx,
  directives_yBPzJQtdia
]
import { useAuthStore } from '~/store/auth.js'

/**
 * Composable that acts as an API client.
 * Provides $fetch functions for common operations over a data model.
 * Always expose the following functions for consistency:
 *
 * This (and other such composables) can be used in setup functions and other places
 * in the Nuxt lifecycle for fetching data.
 *
 * Example would be to use the exposed `index` fetcher with `useFetch` or `useAsyncData` like so:
 * ```javascript
 * import useContractors from '~/composables/useContractors'
 * const { index, create } = useContractors()
 *
 * const {
 *   data: contractors,
 *   pending: isLoading,
 *   refresh,
 * } = await useAsyncData('contractors', () => index())
 * ```
 * The benefit here is that if multiple pages need the same data, by using the `useAsyncData` composable
 * in all those pages in the same way (with the same key and fetcher),
 * data fetching would happen on server and client-side seamlessly and requests will be deduplicated.
 */
export default function () {
  const { $pinia } = useNuxtApp()
  const config = useRuntimeConfig()
  const store = useAuthStore($pinia)

  const index = (pageNumber) =>
    $fetch(
      `${config.public.apiBaseUrl}/organizations/${store.user.org_id}/contractors`,
      {
        key: 'contractors',
        params: {
          page: pageNumber,
        },
        headers: {
          Authorization: `Bearer ${store.token}`,
        },
      },
    )

  const getNavContractors = () =>
    $fetch(
      `${config.public.apiBaseUrl}/organizations/${store.user.org_id}/contractors/display/all`,
      {
        headers: {
          Authorization: `Bearer ${store.token}`,
        },
      },
    )

  const create = (data) =>
    $fetch(
      `${config.public.apiBaseUrl}/organizations/${store.user.org_id}/contractors`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${store.token}`,
        },
        body: data,
      },
    )

  const update = (contractorId, data) =>
    $fetch(
      `${config.public.apiBaseUrl}/organizations/${store.user.org_id}/contractors/${contractorId}`,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${store.token}`,
        },
        body: data,
      },
    )

  const updateStatus = (data) =>
    $fetch(
      `${config.public.apiBaseUrl}/organizations/${store.user.org_id}/contractors/display`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${store.token}`,
        },
        body: data,
      },
    )

  const deleteContractor = (contractorId) =>
    $fetch(
      `${config.public.apiBaseUrl}/organizations/${store.user.org_id}/contractors/${contractorId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${store.token}`,
        },
      },
    )

  const getAllContractors = () =>
    $fetch(
      `${config.public.apiBaseUrl}/organizations/${store.user.org_id}/contractors/all`,
      {
        headers: {
          Authorization: `Bearer ${store.token}`,
        },
      },
    )

  return {
    index,
    create,
    update,
    deleteContractor,
    getAllContractors,
    updateStatus,
    getNavContractors,
  }
}

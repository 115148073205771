import { defineNuxtPlugin } from '#app/nuxt'
import { LazyDemoAccountMenu, LazyDemoCircularMenuActivity, LazyDemoCircularMenuLanguage, LazyDemoCircularMenuNotifications, LazyDemoCollapseNavigationFooter, LazyDemoCollapseNavigationHeader, LazyDemoPanelActivity, LazyDemoPanelLanguage, LazyDemoPanelSearch, LazyDemoPanelTask, LazyDemoSubsidebarDashboards, LazyDemoSubsidebarLayouts, LazyDemoThemeToggle, LazyDemoToolbarAccountMenu, LazyDemoToolbarActivity, LazyDemoToolbarLanguage, LazyDemoToolbarNotifications, LazyPanelContractorComments, LazyPanelContractorDetails, LazyPanelContractorFilters, LazyPanelCreateContractor, LazyTairoLogo, LazyTairoLogoText, LazyTairoToaster, LazyTairoTocAnchor, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["DemoAccountMenu", LazyDemoAccountMenu],
["DemoCircularMenuActivity", LazyDemoCircularMenuActivity],
["DemoCircularMenuLanguage", LazyDemoCircularMenuLanguage],
["DemoCircularMenuNotifications", LazyDemoCircularMenuNotifications],
["DemoCollapseNavigationFooter", LazyDemoCollapseNavigationFooter],
["DemoCollapseNavigationHeader", LazyDemoCollapseNavigationHeader],
["DemoPanelActivity", LazyDemoPanelActivity],
["DemoPanelLanguage", LazyDemoPanelLanguage],
["DemoPanelSearch", LazyDemoPanelSearch],
["DemoPanelTask", LazyDemoPanelTask],
["DemoSubsidebarDashboards", LazyDemoSubsidebarDashboards],
["DemoSubsidebarLayouts", LazyDemoSubsidebarLayouts],
["DemoThemeToggle", LazyDemoThemeToggle],
["DemoToolbarAccountMenu", LazyDemoToolbarAccountMenu],
["DemoToolbarActivity", LazyDemoToolbarActivity],
["DemoToolbarLanguage", LazyDemoToolbarLanguage],
["DemoToolbarNotifications", LazyDemoToolbarNotifications],
["PanelContractorComments", LazyPanelContractorComments],
["PanelContractorDetails", LazyPanelContractorDetails],
["PanelContractorFilters", LazyPanelContractorFilters],
["PanelCreateContractor", LazyPanelCreateContractor],
["TairoLogo", LazyTairoLogo],
["TairoLogoText", LazyTairoLogoText],
["TairoToaster", LazyTairoToaster],
["TairoTocAnchor", LazyTairoTocAnchor],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

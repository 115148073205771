/**
 * This file is used to configure the app
 *
 * If you have the "Cannot find name 'defineAppConfig'.ts(2304)" error
 * update the root tsconfig.json file to include the following:
 *
 *  "extends": "./.app/.nuxt/tsconfig.json"
 *
 */

export default defineAppConfig({
  tairo: {
    title: 'Insurance',
    collapse: {
      toolbar: {
        enabled: true,
        showTitle: true,
        showNavBurger: true,
        tools: [
          {
            component: 'DemoThemeToggle',
            props: {
              disableTransitions: true,
            },
          },
          // Dynamically added during layout render
          // {
          //   component: 'DemoToolbarNotifications',
          // },
          {
            component: 'DemoToolbarAccountMenu',
          },
        ],
      },
      circularMenu: {
        enabled: true,
        tools: [
          {
            component: 'DemoThemeToggle',
            props: {
              class: 'ms-auto',
              disableTransitions: true,
              inverted: true,
            },
          },
          {
            component: 'DemoCircularMenuNotifications',
          },
        ],
      },
      navigation: {
        enabled: true,
        header: {
          component: 'DemoCollapseNavigationHeader',
        },
        items: [
          {
            name: 'Dashboard',
            icon: { name: 'ph:squares-four', class: 'w-[20px] h-[20px]' },
            activePath: '/dashboard',
            to: '/dashboard',
          },
          {
            name: 'Insurance Companies',
            to: '/insurance-companies',
            icon: {
              name: 'solar:buildings-outline',
              class: 'w-[20px] h-[20px]',
            },
          },
          {
            name: 'Contractors',
            to: '/contractors',
            icon: {
              name: 'ph:suitcase-simple',
              class: 'w-[20px] h-[20px]',
            },
          },
          {
            name: 'My Organization',
            icon: { name: 'ph:circles-three', class: 'w-[20px] h-[20px]' },
            activePath: '',
            children: [
              {
                name: 'Details',
                to: '/details',
                icon: {
                  name: 'ph:list-magnifying-glass',
                  class: 'w-[20px] h-[20px]',
                },
              },
              {
                name: 'Users',
                to: '/users',
                icon: {
                  name: 'ph:users-three',
                  class: 'w-[20px] h-[20px]',
                },
              },
              {
                name: 'Roles',
                to: '/roles',
                icon: {
                  name: 'ph:users',
                  class: 'w-[20px] h-[20px]',
                },
              },

              {
                name: 'Statuses',
                to: '/statuses',
                icon: {
                  name: 'ph:list-checks',
                  class: 'w-[20px] h-[20px]',
                },
              },
              {
                name: 'Subscription',
                to: '/subscription',
                icon: {
                  name: 'ph:credit-card',
                  class: 'w-[20px] h-[20px]',
                },
              },
            ],
          },

          {
            name: 'Divider',
            divider: true,
          },
        ],
      },
    },
    panels: [
      {
        name: 'activity',
        position: 'right',
        component: 'DemoPanelActivity',
      },
      {
        name: 'createContractor',
        position: 'right',
        component: 'PanelCreateContractor',
      },
      {
        name: 'contractorDetails',
        position: 'right',
        component: 'PanelContractorDetails',
      },
      {
        name: 'contractorComments',
        position: 'right',
        component: 'PanelContractorComments',
      },
      {
        name: 'contractorFilters',
        position: 'right',
        component: 'PanelContractorFilters',
      },
    ],
  },
})
